import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Helmet from "react-helmet"

import CodingLogos from "../../components/codingLogos.js"
import PartnersLogos from "../../components/partnerLogos.js"

import ReqCur from "../../components/reqCur"

import Covid from "../../components/covid"
import { Stripe } from "stripe.js"
import Checkout from "../../components/checkout"

import Contacts from "../../components/contactform"
import Scholarships from "../../components/scholarships.js"
import InternationalStudents from '../../components/internationalStudents.js'
import JSProjectsExamples from '../../components/JSProjectsExamples.js'

// import Inperson from "../../pics/javascript_fullstack_inperson_bootcamp_2023.jpg"
import Inperson from "../../pics/web_development_bootcamp_barcelona_code_school.jpg"

import OurStudents00 from "../../pics/students/barcelona_code_school_students_00.jpg"
// import OurStudents01 from '../../pics/students/barcelona_code_school_students_01.jpg'
// import OurStudents02 from '../../pics/students/barcelona_code_school_students_02.jpg'
// import OurStudents03 from '../../pics/students/barcelona_code_school_students_03.jpg'

import Instalments from '../../components/instalments.js'

import Ting from "../../pics/students/ting_profile.jpg"
import Ross from "../../pics/students/ross_sq.jpg"
import Christine from "../../pics/students/christine_chang.jpg"
import Brie from "../../pics/students/brie_frontpage.jpg"

// import CR from '../../pics/BestOnlineBadge2020.png'
import switchup from "../../pics/switchup2020.png"

import Countdown from '../../components/Countdown';
import Ratings from '../../components/Ratings';

import ProgressBar from '../../components/ProgressBar';

const JSBootcamp = () => {

	let courses = [{
		durationdays: 61,
		durationweeks: 9,
		fulldesc1: "9-week course, in-person, in English, full-time.",
		fulldesc2: "Registration prepayment 580€.",
		fullprice: 5800,
		image: "https://barcelonacodeschool.com/files/pics/web_development_bootcamp_barcelona_code_school.jpg",
		priceoptions: [],
		shortdesc: "Become a web/mobile developer in 9 weeks",
		signupprice: 580,
		startdate: "April 19, 2021",
		startdates: [{availability:100, date:'January 6 - March 7, 2025'},  {availability:18, date:'March 17 - May 16, 2025'}, {availability:36, date:'May 26 - July 25, 2025'},  {availability:10, date:'August 18 - October 17, 2025'}, {availability:10, date:'October 20 - December 19, 2025'}],
		starttime: "9:00",
		tag: "jsBootcamp",
		title: "Web / Mobile Development in-person Bootcamp",
	}]



	let renderCourses = () => {
		return courses[0].startdates.map((date, i) => (
			<div key={i} className="whiteCard bottomSpace flex300">
				<span>
					<h2><span className='highlighter'>{date.date}</span></h2>
					<h3>{courses[0].title}</h3>
					{i === 1 &&
						<h3>Starts in <Countdown endDate={`${date.date.split(' - ')[0]} ${date.date.slice(-4)} 09:00`} />
						</h3>
					}
					<p>{courses[0].shortdesc}</p>
					{date.date.includes("August 21") && <h2 className='highlighter'>1 place left!</h2>}
				</span>
				<span>
					<p>{courses[0].fulldesc1}</p>
					<p>{courses[0].fulldesc2}</p>
					{/* {date.date.includes('January 6 - March 7, 2025') && <p className='highlighter'>Less than 50% places left</p>} */}

					{/* {date.date.includes("March 11 – May 10, 2024") && <div className='highlighter'><h2>Join the waitlist</h2><p className='finePrint' style={{ display: 'block' }}>If one of the places will become available you will be notified straight away.</p> <p className='finePrint' style={{ display: 'block' }}>If not, you will get a full refund.</p></div>} */}

					 <ProgressBar percentage={date.availability}/>

					{i===0&&<><h3><span className='highlighter'>Group is full</span></h3></>}

					{!date.date.includes("January 6") && <Link
						to="/register"
						state={{
							course: courses[0].title,
							date: date.date,
							cost: courses[0].signupprice,
							images: [courses[0].image],
							cryptoID: '89fc29c4-f6b4-4b2b-b56b-e68ae8d46b55'
						}}
					>
						<button style={{ marginBottom: "1em" }}>{i===0 ? 'Join the waitlist' : 'Sign up'}</button>
					</Link>}
				</span>
			</div>
		))
	}
	

	return (
		<div className="flexContent">
			<Helmet
				title="Web / Mobile Development Bootcamp in Barcelona Code School - learn React, React Native, Node, Express, MongoDB"
				meta={[
					{
						name: "description",
						content:
							"Web / Mobile Development Bootcamp in Barcelona Code School. Intensive 9-week course to become a developer. Teaching JavaScript, TypeScript, React, React Native, Node, Express, MongoDB, Git, HTML, CSS and all the hands-on skills and techniques needed to build web or native mobile apps.",
					},
					{
						name: "keywords",
						content:
							"Web / Mobile Development bootcamp, javascript bootcamp in Barcelona, web development bootcamp in Barcelona, react bootcamp, web development javascript bootcamp",
					},
					{
						name: "viewport",
						content: "width=device-width, initial-scale=1",
					},
					{
						property: "og:title",
						content:
							"Web / Mobile Development Bootcamp in Barcelona Code School - learn React, React Native, Node, Express, MongoDB",
					},
					{
						property: "og:description",
						content:
							"Web / Mobile Development Bootcamp in Barcelona Code School. Intensive 9-week course to become a developer. Teaching JavaScript, TypeScript, React, React Native, Node, Express, MongoDB, Git, HTML, CSS and all the hands-on skills and techniques needed to build web or native mobile apps.",
					},
					{
						property: "og:image",
						content:
							"https://barcelonacodeschool.com/files/pics/web_development_bootcamp_barcelona_code_school.jpg",
					},
					{
						property: "og:url",
						content:
							"https://barcelonacodeschool.com/barcelona-code-school/javascript-full-stack-bootcamp/",
					},
					{ name: "twitter:card", content: "summary_large_image" },
				]}
				script={[{ src: "https://js.stripe.com/v3/" }]}
			/>

			<img
				className="banners flex1200 offsetImage"
				src={Inperson}
				alt="Web / Mobile Development Bootcamp in Barcelona Code School"
			/>

			<h1 className='transparentContainer flex225'><span className="highlighter">Web / Mobile Development in-person Bootcamp</span> in Barcelona Code School</h1>

			<h2 className='transparentContainer flex450'>Become a full-stack developer and build web and mobile apps powered by JavaScript</h2>

			<article className='transparentContainer flex450'>
				<video src={'https://barcelonacodeschool.com/files/videos/JavaScript_Full-Stack_Bootcamp_in_Barcelona_Code_School.mp4'} width="100%" height="100%" controls autoPlay muted loop type="video/mp4" />
			</article>

			<div className='transparentContainer flex450'>
				<h3>What is a web/mobile developer?</h3>
				<div className='whiteCard'>
					<p>A <strong>web/mobile developer builds, productionizes, optimizes and maintains web and mobile applications</strong>.</p>
					<p>A <strong>web application</strong> is <strong className='highlighter'>a website with functionality and user interactions</strong>, which is not only displaying static content but also allows users to do something. For example, <span className='highlighter'>YouTube</span> (users can upload videos, watch them, comment, etc..), <span className='highlighter'>Reddit</span> (users can post, read, comment, etc...), <span className='highlighter'>Amazon</span> (users can buy products, sellers can sell them, etc...) are all web applications.</p>
					<p>A <strong>mobile app</strong> is any app you can <strong className='highlighter'>install in your mobile phone</strong>. For example, <span className='highlighter'>Weather</span>, <span className='highlighter'>Notes</span>, <span className='highlighter'>Mail</span>, <span className='highlighter'>TikTok</span>, <span className='highlighter'>Telegram</span>, <span className='highlighter'>Netflix</span> and so on...</p>
					<p>Our <strong>course</strong> is focused primarly on <strong>developing web and mobile apps</strong> (for both iOS and Android) with the <strong>JavaScript MERN stack</strong> which includes <strong className='highlighter'>React</strong>, <strong className='highlighter'>TypeScript</strong>, <strong className='highlighter'>Node</strong>, <strong className='highlighter'>Express</strong>, <strong className='highlighter'>mongoDB</strong> and of course <strong className='highlighter'>JavaScript</strong>, <strong className='highlighter'>HTML</strong>, <strong className='highlighter'>CSS</strong> + <strong className='highlighter'>React Native</strong> for mobile developemnt.</p>
				</div>
			</div>

			<div className='transparentContainer flex225'>
				<p className='whiteCard'>Bootcamps since 2017 with continuous updates and improvements</p>
				<div className="flexOneRowCenter flex300">
					<img
						className="badge"
						src={switchup}
						alt="SwitchUp Best Coding Bootcamp 2020"
					/>
				</div>
				<Ratings />
			</div>

			<div className="transparentContainer">
				<h4>Share on social media</h4>
				<div className="socialsharebuttons">
					<div className="socialshare" style={{ 'marginLeft': 0 }}>
						<a
							className="twitter-share-button "
							href="https://twitter.com/intent/tweet?text='Web / Mobile Development in-person Bootcamp in Barcelona Code School'&url=https://barcelonacodeschool.com/barcelona-code-school/javascript-full-stack-bootcamp/"
							data-size="large"
						>
							<button>tweet</button>
						</a>
					</div>

					<div
						className="fb-share-button socialshare"
						data-href="https://developers.facebook.com/docs/plugins/"
						data-layout="button"
						data-size="small"
					>
						<a
							target="_blank"
							href="https://www.facebook.com/sharer/sharer.php?u=https://barcelonacodeschool.com/barcelona-code-school/javascript-full-stack-bootcamp/"
							className="fb-xfbml-parse-ignore"
						>
							<button>facebook</button>
						</a>
					</div>
				</div>
			</div>


			<article className='transparentContainer flex600'>
				<h2>Get our awesome syllabus</h2>
				<div className='whiteCard'>
					<p>Subscribe to our mailing list and get the syllabus now.</p>
					<ReqCur
						course="Web / Mobile Development Bootcamp"
						link="https://barcelonacodeschool.com/files/BCS_Web_and_Mobile_Development_Bootcamp_Syllabus.pdf"
						courselink="https://www.barcelonacodeschool.com/barcelona-code-school/javascript-full-stack-bootcamp/"
					/>
				</div>
			</article>



			<article className='transparentContainer flex300'>
				<h2>Course format</h2>
				<ul className=' whiteCard neonList'>
					<li>360 hours of intensive <strong className="highlighter">in-person hands-on</strong> training</li>
					<li>Zero to hero in	<strong className="highlighter">9 weeks</strong></li>
					<li>Monday to Friday, 9:00 to 18:00</li>
					<li>Small groups <span className='highlighter'>up to 6 students</span></li>
					<li>The course is taught in	<strong className="highlighter">English</strong></li>
					<li><strong className="highlighter">Scholarships</strong> are available</li>
					<li>Coding bootcamp teaching <strong className="highlighter">web</strong> and <strong className="highlighter">mobile</strong> apps development!</li>
					<li>A <strong className="highlighter">graduate certificate</strong></li>
					<li>Job support</li>
				</ul>
			</article>




			<article className="transparentContainer flex600">
				<h2>What you'll learn</h2>
				<div className='whiteCard'>
					<CodingLogos />
					<ul className='neonList'>
						<li>How to build fast, modern, dynamic and responsive <span className="highlighter">web apps</span></li>
						<li>Building native	<span className="highlighter">mobile apps</span> for iOS and Android</li>
						<li><span className="highlighter">HTML, CSS, JavaScript, Node, Express, MongoDB, React.js, TypeScript, React Native, Git, UX basics</span></li>
						<li>Using database</li>
						<li>Using external APIs</li>
						<li>Version control with Git and GitHub</li>
						<li><span className="highlighter">Deployment</span> of your web apps with Linux and nginx</li>
						<li>Best web development practices and advanced techniques</li>
						<li>Team and pair programming, programming logic, algorithmic approach </li>
						<li><span className="highlighter"> The only school that teaches back-end, front-end and native mobile apps development</span> </li>
						<li>The most in-demand tools in the job market</li>
					</ul>
				</div>
			</article>



			<article className="transparentContainer flex900" >
				<h2>The outcomes</h2>
				<div className='whiteCard'>
					<ul className='neonList'>
						<li><span className="highlighter">Get hired as a developer</span>. On average it takes 1-3 months after the course to get hired.</li>
						<li><span className="highlighter">Start freelancing</span>. Get the practical skills and a portfolio projects you need to start working remotely in the in-demand field of web/mobile development.</li>
						<li><span className="highlighter">Build your own apps</span>. Tackle the development yourself and acquire lifelong skills in the process.</li>
						<li>Take a look at some of the	<span className="highlighter"> <a href="https://projects.barcelonacodeschool.com/" target="_blank" >students projects</a> </span>	built during the bootcamp.</li>
					</ul>
				</div>
			</article>







			<article className='transparentContainer flex300'>
				<h2>Tuition</h2>
				<div className='whiteCard'>
					<h2>5800€</h2>
					<p>10% paid upon registration, the remaining is split in two payments due before course begins.</p>
					<Instalments />
				</div>
			</article>


			<article className="transparentContainer flex1200">
				<h2>Access

					<span
						id="cohorts"
						style={{
							position: "relative",
							top: "-50px",
							visibility: "hidden",
						}}
					>
						.
					</span>
				</h2>

				{/*<Covid course={"jsinperson"} />*/}
				<div className='flexContent'>{courses.length > 0 && renderCourses()}</div>




			</article>



			<JSProjectsExamples />



			<section className="flex1200 transparentContainer">
				<h2>Students stories</h2>

				<div className="flexContent">
					<div className="whiteCard flex225">
						<img className="profilePic offsetImage" src={Christine} />
						<h3>Christine Chang</h3>
						<h4>Junior Developer at Green Power Monitor, Barcelona</h4>
						<p>
							"I wish I had done code camp 5 years ago. I would have
							been working all these years making up the tuition cost
							in a month or two. I chose Barcelona Code School because
							they had a focus on React and React Native."
						</p>
						<p>
							<a href="/student-story-christine-chang">
								Read full story
							</a>
							.
						</p>
					</div>
					<div className="whiteCard flex225">
						<img className="profilePic offsetImage" src={Brie} />
						<h3>Brianna Fritsch</h3>
						<h4>Website Manager at IESE, Barcelona</h4>
						<p>
							"The instructors continued to motivate me and remind me
							that I would get it if I kept practicing. They
							encouraged me to keep going because if it was that easy
							to become a developer then everyone would be doing it.
							They really helped me to keep going even my moments of
							frustration and defeat."
						</p>
						<p>
							<a href="/students-story-brie">Read full story</a>.
						</p>
					</div>

					<div className="whiteCard flex225">
						<img className="profilePic offsetImage" src={Ting} />
						<h3>Ting Liu</h3>
						<h4>Junior developer at Metro AG, Berlin</h4>
						<p>
							"I made an extra studying plan, I spent 1-2 hours a day
							at home reading some programming-related articles to
							help myself understand better my code."
						</p>
						<p>
							<a href="/students-outcomes/ting">Read full story</a>.
						</p>
					</div>

					<div className="whiteCard flex225">
						<img className="profilePic offsetImage" src={Ross} />
						<h3>Ross Fleming</h3>
						<h4>Front-end developer at Raet, Madrid</h4>
						<p>
							“If you put a 100% percent of yourself, you get the same
							in return and can start looking for your new job as a
							developer. It took me a bit more than one month to find
							a job, it is incredible. To those who have never had
							experience of intensive training, bootcamps can seem to
							be some kind of wizard schools”.
						</p>
						<p>
							<a href="/students-outcomes/ross">Read full story</a>.
						</p>
					</div>
				</div>
			</section>



			<article className="transparentContainer flex450">
				<h2>Why JavaScript?</h2>
				<div className='whiteCard'>
					<ul>
						<li>According to StackOverflow.com, the biggest community website for professional and enthusiast programmers, JavaScript has been <a href="https://survey.stackoverflow.co/2022/#programming-scripting-and-markup-languages" target='blank'> the world's most popular language for 10 years in a row </a>. </li>
						<li>Knowing JavaScript opens the door into web and mobile apps development without any limitations. Back-end, front-end, full-stack, iOS and Android apps development is all possible with JavaScript. </li>
						<li>Full-stack developer is <a href="https://barcelonacodeschool.com/why-learning-javascript-is-relevant-today"> the most in-demand role in the job market </a>. </li>
					</ul>
				</div>
			</article>

			<article className="transparentContainer flex450">
				<h2>Why Barcelona Code School?</h2>
				<div className='whiteCard'>
					<ul>
						<li><span className="highlighter">Cutting edge curriculum</span> tailored to job market and companies’ needs</li>
						<li>Instructors, who <span className="highlighter">know how to teach</span></li>
						<li>Fast track from beginner to job-ready coder</li>
						<li>The best <span className="highlighter">students/mentors ratio</span></li>
						<li><span className="highlighter">Job offers</span> for the students constantly coming from our <span className="highlighter">hiring partners</span></li>
						<li>Life-time career support</li>
						<li>Premium location – cozy campus in the downtown of Barcelona away from the touristic crowds</li>
					</ul>
				</div>
			</article>




			<InternationalStudents />

			<Scholarships />

			<article className='transparentContainer flex300'>
				<h2>Financing</h2>
				<div className='whiteCard'>
					<p>We can assist in getting a student loan which usually has lower rates than consumer loans.</p>
					<p>To do so we can send you a proforma invoice for the selected course which you can attach to the student's loan application.</p>
					<p>Please <a href="https://docs.google.com/forms/d/e/1FAIpQLSeIeOLI2k3DlSQmwdUFPMawFr7-LUVWEnd2Huy4mMaRu0bncg/viewform" target="_blank">fill out this form</a>.</p>
					<Instalments />
				</div>
			</article>








			<div className='flex900'><img className="banners offsetImage" src={OurStudents00} /></div>



			<article className="transparentContainer flex450 transparentContainer">
				<h2>Wondering about day-to-day workflow?</h2>
				<div className='whiteCard'>
					<p>Generally speaking we will have a lesson for the new materials in the morning and practical work afterwards. The ratio is about 20% of theory and 80% of coding.</p>
				</div>
			</article>




			<article className='transparentContainer flex450'>
				<h2>FAQ</h2>
				<div className='whiteCard'>
					<details>
						<summary><strong>What computer would I need?</strong></summary>
						<p>Any operating system will do, Mac, Windows or Linux. If you are thinking about buying a new one then the best choice would be an Apple laptop: Macbook, Macbook Air or Macbook Pro. On Windows laptop everything is doable as well, simply some things work slower on Windows but it's not going to prevent you from succeeding!</p>
					</details>

					<details>
						<summary><strong>What is the language of this course?</strong></summary>
						<p>English.</p>
					</details>

					<details>
						<summary><strong>Do I have to speak Spanish?</strong></summary>
						<p>For the course you do not need Spanish. Everybody at our school speak English. To feel comfortable in Barcelona Spanish would help but nowadays in most of the places local people do speak English.</p>
					</details>

					<details>
						<summary><strong>What qualification I will get from this course?</strong></summary>
						<p>If completed successfully you will become a junior full-stack developer. Meaning you will know how to build web apps and be able to apply for the entry developer's position.</p>
					</details>

					<details>
						<summary><strong>Are there any accommodation options for the foreign
							students?</strong></summary>
						<p>Make sure to check <a
							href="https://www.uniplaces.com/?utm_source=vero&utm_medium=email&utm_content=control&utm_campaign=Institutionals_Update%20Tracking%20%26%20Infos_%20Actives%2FNon%20Actives&utm_term=Newsletter&vero_id=office%40barcelonacodeschool.com&vero_conv=Thn_9YrfYIN-ruvFIRw_AXMfUSaz0geBOB0eCva7t8s8rOGu5-ZqtuEkgRV0KkLu2FE-G1Qg-iJ5o_8ynL7xjBY93Og9tI-Api0%3D"
							target="_blank"
						>Uniplaces (use UP25BCS promo code to get 25% discount)</a>, <a
							href="https://www.spotahome.com/barcelona"
							target="_blank"
						>Spotahome</a> and <a href="https://barcelona-home.com/en" target="_blank">Barcelona home</a>.</p>
					</details>

					<details>
						<summary><strong>What about cost of living in Barcelona, how much will I spend apart from the course tuition?</strong></summary>
						<p>Barcelona, compared with other metropolitan European cities, is quite affordable or even cheap place to live. According to the latest survey, the average cost of living for students in Barcelona is around 1000 Euros per month.</p>
						<p>Expect to spend 300-600€ to rent a room or 600-1000€ for the apartment, around 10€ for a lunch in a restaurant, 10€ for a public transport 10-ride pass.</p>
					</details>

					<details>
						<summary><strong>How good would a typical graduate be after completing the course?</strong></summary>
						<p>You will learn the skills to begin as a junior full-stack developer, will know how to build fully functional web apps and have a solid foundation to improve your expertise.</p>
					</details>

					<details>
						<summary><strong>Is there a pre-course?</strong></summary>
						<p>Yes. We send you materials to study to come prepared! We estimate it to take around 40 hours if you start from absolutely 0 level of knowledge of JavaScript, HTML and CSS.</p>
					</details>

					<details>
						<summary><strong>How does your price compare to other schools?</strong></summary>
						<p>It’s safe to say that our price is within lower competitive range. We strive to provide the personalized approach thanks to smaller group size and attract highly qualified instructors motivated to share their expertise with students. You will love it, we guarantee!</p>
					</details>
				</div>
			</article>

			<div className='flex450'>
				<Contacts
					title={"Inquiry about the course"}
					subject={"Inquiry about JS in-person bootcamp"}
				/>
			</div>

			<article className='transparentContainer flex900'>
				<h2>Some of the companies where our graduates work or worked</h2>
				<div>
					<PartnersLogos />
				</div>
			</article>

		</div>

	)
}

export default JSBootcamp
